import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import LoginPage from "./components/LoginPage";
import { useAuth } from "./auth";
import LeadsList from "./components/LeadsList";
import { getFromLocal } from "./utils/localStorage";
import LeadDetails from "./components/LeadDetails";
import Capture from "./components/Capture";
import MediaListPage from "./components/MediaListPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/public" element={<PublicPage />} /> */}

        <Route
          path="/leadsList"
          element={
            <PrivateRoute>
              <LeadsList />
            </PrivateRoute>
          }
        />
        <Route
          path="/leadDetails"
          element={
            <PrivateRoute>
              <LeadDetails />
            </PrivateRoute>
          }
        />
        <Route path="/capture" element={<Capture />} />
        <Route
          path="/mediaListPage"
          element={
            <PrivateRoute>
              <MediaListPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

function PrivateRoute({ children }) {
  const isAuthenticated = getFromLocal("isAuthenticated") ?? false;
  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default App;
