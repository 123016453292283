import React, { useEffect, useState } from "react";
import { getLeads } from "../../api/appLeads";
import "./LeadsList.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import CircularLoader from "../CircularLoader";

function LeadsList() {
  //   const { logout } = useAuth();
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLeads()
      .then((res) => {
        setLeads(res.data?.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      <h5 className="leads-heading">Open Leads</h5>
      <div className="leadsList-container">
        {isLoading && <CircularLoader />}
        {leads?.map((lead, index) => (
          <div
            key={`leads${index}`}
            onClick={() => {
              let leadData = lead.find(
                (item) => item.display_name === "Lead Id"
              );
              navigate(`/leadDetails?leadId=${leadData.value}`);
            }}
            className="leadsList-card"
          >
            {lead?.map((item) => (
              <p key={item.display_name}>
                <span>{item.display_name}</span>: {item.value}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LeadsList;
