import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import "./MediaListPage.css";
import NoImageFound from "../../assets/NoImageFound.jpg";
import GreenTick from "../../assets/GreenTick.svg";
import CHASSIS from "../../assets/Chaissis.jpeg";
import Truck_back from "../../assets/Truck_back.png";
import Truck_engine_with_bonnet_open from "../../assets/Truck_engine_with_bonnet_open.jpeg";
import Truck_ws_glass_from_outside from "../../assets/Truck_ws_glass_from_outside.png";
import Truck_left from "../../assets/Truck_left.png";
import Truck_ws_glass_with_dashboard from "../../assets/Truck_ws_glass_with_dashboard.png";
import Truck_Front_Left45Degree from "../../assets/Truck_Front_Left45Degree.png";
import Truck_Front from "../../assets/Truck_Front.png";
import TruckBackLeft45Degree from "../../assets/TruckBackLeft45Degree.png";
import Truck_Front_Right45Degree from "../../assets/Truck_Front_Right45Degree.png";
import TruckBackRight45Degree from "../../assets/TruckBackRight45Degree.png";
import Capture from "../Capture";
import { getUploadedMediaById, submitInspection } from "../../api/appLeads";
import { toast } from "react-toastify";
/*

"FRONT PHOTO": Truck_Front,
"FRONT RIGHT 45 DEGREE": Truck_Front_Right45Degree,
"FRONT LEFT 45 DEGREE": Truck_Front_Left45Degree,
BACK: Truck_back, 
"BACK RIGHT 45 DEGREE": TruckBackRight45Degree,
"BACK LEFT 45 DEGREE": TruckBackLeft45Degree,
RIGHT: NoImageFound, //check
LEFT: Truck_left,
"ODOMETER WITH RPM": NoImageFound,
"ENGINE PHOTO KICK SIDE": NoImageFound,
"FRONT UNDERCARIAGE": NoImageFound,
"BACK UNDERCARIAGE": NoImageFound,
"ENGINE PHOTO BONET OPEN": Truck_engine_with_bonnet_open,
"W/S GLASS PHOTO INSIDE WITH DASH BOARD": Truck_ws_glass_with_dashboard,
"W/S GLASS PHOTO OUTSIDE": Truck_ws_glass_from_outside,
"CHASSIS PHOTO": CHASSIS,
"VIN PLATE PHOTO": NoImageFound,
"Front Left Tyre": NoImageFound, //check
"Front Right Tyre": NoImageFound, //check
"Back Left Tyre": NoImageFound, //check
"Back Right Tyre": NoImageFound, //check
"Front Left Tyre": NoImageFound, //check
"Front Tyre": NoImageFound, //check
"Back Tyre": NoImageFound, //check
SELFI: NoImageFound,
Others: NoImageFound,
Video: NoImageFound,






const uploadTypes = {
  "CHASSIS PHOTO": CHASSIS,
  "Front Right": NoImageFound, //check
  "Front Left": NoImageFound, //check
  "FRONT PHOTO": Truck_Front,
  "FRONT RIGHT 45 DEGREE": Truck_Front_Right45Degree,
  BACK: Truck_back,
  "BACK RIGHT 45 DEGREE": TruckBackRight45Degree,
  "BACK LEFT 45 DEGREE": TruckBackLeft45Degree,
  RIGHT: NoImageFound, //check
  LEFT: Truck_left,
  "FRONT UNDERCARIAGE": NoImageFound,
  "BACK UNDERCARIAGE": NoImageFound,
  "ODOMETER WITH RPM": NoImageFound,
  "W/S GLASS PHOTO INSIDE WITH DASH BOARD": Truck_ws_glass_with_dashboard,
  "W/S GLASS PHOTO OUTSIDE": Truck_ws_glass_from_outside,
  "ENGINE PHOTO BONET OPEN": Truck_engine_with_bonnet_open,
  "VIN PLATE PHOTO": NoImageFound,
  SELFI: NoImageFound,
  Others: NoImageFound,
  Video: NoImageFound,
  "CHASSIS/VIN PLATE": NoImageFound,
  "FRONT LEFT 45 DEGREE": Truck_Front_Left45Degree,
  "ENGINE PHOTO KICK SIDE": NoImageFound,
};
*/




const MediaListPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const leadId = urlParams.get("leadId");
  const regNo = urlParams.get("regNo");
  const vehicleType = urlParams.get("vehicleType");
  const [uploadedDocsTypes, setUploadedDocsTypes] = useState([]);
  const [uploadedDocsDetails, setUploadedDocsDetails] = useState([]);
  const [isCapturePage, setIsCapturePage] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toggleType, setToggleType] = useState("UPLOAD");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  // Declare the uploadTypes variable here
  let uploadTypes = {};

  // Assign values to uploadTypes based on vehicleType
  if (vehicleType === '2 Wheeler') {
    uploadTypes = {
          "FRONT PHOTO": Truck_Front,
          "FRONT RIGHT 45 DEGREE": Truck_Front_Right45Degree,
          "FRONT LEFT 45 DEGREE": Truck_Front_Left45Degree,
          BACK: Truck_back, 
          "BACK RIGHT 45 DEGREE": TruckBackRight45Degree,
          "BACK LEFT 45 DEGREE": TruckBackLeft45Degree,
          RIGHT: NoImageFound, //check
          LEFT: Truck_left,
          "ODOMETER WITH RPM": NoImageFound,
          "ENGINE PHOTO KICK SIDE": NoImageFound,
          "CHASSIS PHOTO": CHASSIS,
          "VIN PLATE PHOTO": NoImageFound,
          "Front Left Tyre": NoImageFound, //check
          "Front Right Tyre": NoImageFound, //check
          "Back Left Tyre": NoImageFound, //check
          "Back Right Tyre": NoImageFound, //check
          "Front Left Tyre": NoImageFound, //check
          "Front Tyre": NoImageFound, //check
          "Back Tyre": NoImageFound, //check
          SELFI: NoImageFound,
          Others: NoImageFound,
          Video: NoImageFound,
    };
  } else if (vehicleType === '4 Wheeler') {
    uploadTypes = {
          "FRONT PHOTO": Truck_Front,
          "FRONT RIGHT 45 DEGREE": Truck_Front_Right45Degree,
          "FRONT LEFT 45 DEGREE": Truck_Front_Left45Degree,
          BACK: Truck_back, 
          "BACK RIGHT 45 DEGREE": TruckBackRight45Degree,
          "BACK LEFT 45 DEGREE": TruckBackLeft45Degree,
          RIGHT: NoImageFound, //check
          LEFT: Truck_left,
          "ODOMETER WITH RPM": NoImageFound,
          "FRONT UNDERCARIAGE": NoImageFound,
          "BACK UNDERCARIAGE": NoImageFound,
          "ENGINE PHOTO BONET OPEN": Truck_engine_with_bonnet_open,
          "W/S GLASS PHOTO INSIDE WITH DASH BOARD": Truck_ws_glass_with_dashboard,
          "W/S GLASS PHOTO OUTSIDE": Truck_ws_glass_from_outside,
          "CHASSIS PHOTO": CHASSIS,
          "VIN PLATE PHOTO": NoImageFound,
          "Front Left Tyre": NoImageFound, //check
          "Front Right Tyre": NoImageFound, //check
          "Back Left Tyre": NoImageFound, //check
          "Back Right Tyre": NoImageFound, //check
          "Front Left Tyre": NoImageFound, //check
          SELFI: NoImageFound,
          Others: NoImageFound,
          Video: NoImageFound,
    };
  }else if (vehicleType === 'Commercial') {
    uploadTypes = {
        "FRONT PHOTO": Truck_Front,
        "FRONT RIGHT 45 DEGREE": Truck_Front_Right45Degree,
        "FRONT LEFT 45 DEGREE": Truck_Front_Left45Degree,
        BACK: Truck_back, 
        "BACK RIGHT 45 DEGREE": TruckBackRight45Degree,
        "BACK LEFT 45 DEGREE": TruckBackLeft45Degree,
        RIGHT: NoImageFound, //check
        LEFT: Truck_left,
        "ODOMETER WITH RPM": NoImageFound,
        "FRONT UNDERCARIAGE": NoImageFound,
        "BACK UNDERCARIAGE": NoImageFound,
        "ENGINE PHOTO BONET OPEN": Truck_engine_with_bonnet_open,
        "W/S GLASS PHOTO INSIDE WITH DASH BOARD": Truck_ws_glass_with_dashboard,
        "W/S GLASS PHOTO OUTSIDE": Truck_ws_glass_from_outside,
        "CHASSIS PHOTO": CHASSIS,
        "VIN PLATE PHOTO": NoImageFound,
        "Front Left Tyre": NoImageFound, //check
        "Front Right Tyre": NoImageFound, //check
        "Back Left Tyre": NoImageFound, //check
        "Back Right Tyre": NoImageFound, //check
        "Front Left Tyre": NoImageFound, //check
        SELFI: NoImageFound,
        Others: NoImageFound,
        Video: NoImageFound,
    };
  } else if (vehicleType === '3 Wheeler') {
    uploadTypes = {
            "FRONT PHOTO": Truck_Front,
            "FRONT RIGHT 45 DEGREE": Truck_Front_Right45Degree,
            "FRONT LEFT 45 DEGREE": Truck_Front_Left45Degree,
            BACK: Truck_back, 
            "BACK RIGHT 45 DEGREE": TruckBackRight45Degree,
            "BACK LEFT 45 DEGREE": TruckBackLeft45Degree,
            RIGHT: NoImageFound, //check
            LEFT: Truck_left,
            "ODOMETER WITH RPM": NoImageFound,
            "FRONT UNDERCARIAGE": NoImageFound,
            "BACK UNDERCARIAGE": NoImageFound,
            "ENGINE PHOTO BONET OPEN": Truck_engine_with_bonnet_open,
            "W/S GLASS PHOTO INSIDE WITH DASH BOARD": Truck_ws_glass_with_dashboard,
            "W/S GLASS PHOTO OUTSIDE": Truck_ws_glass_from_outside,
            "CHASSIS PHOTO": CHASSIS,
            "VIN PLATE PHOTO": NoImageFound,
            "Back Left Tyre": NoImageFound, //check
            "Back Right Tyre": NoImageFound, //check
            "Front Left Tyre": NoImageFound, //check
            "Front Tyre": NoImageFound, //check
            SELFI: NoImageFound,
            Others: NoImageFound,
            Video: NoImageFound,
    };
  } else if (vehicleType === 'cpm/others') {
    uploadTypes = {
            "FRONT PHOTO": Truck_Front,
            "FRONT RIGHT 45 DEGREE": Truck_Front_Right45Degree,
            "FRONT LEFT 45 DEGREE": Truck_Front_Left45Degree,
            BACK: Truck_back, 
            "BACK RIGHT 45 DEGREE": TruckBackRight45Degree,
            "BACK LEFT 45 DEGREE": TruckBackLeft45Degree,
            RIGHT: NoImageFound, //check
            LEFT: Truck_left,
            "ODOMETER WITH RPM": NoImageFound,
            "FRONT UNDERCARIAGE": NoImageFound,
            "BACK UNDERCARIAGE": NoImageFound,
            "ENGINE PHOTO BONET OPEN": Truck_engine_with_bonnet_open,
            "W/S GLASS PHOTO INSIDE WITH DASH BOARD": Truck_ws_glass_with_dashboard,
            "W/S GLASS PHOTO OUTSIDE": Truck_ws_glass_from_outside,
            "CHASSIS PHOTO": CHASSIS,
            "VIN PLATE PHOTO": NoImageFound,
            SELFI: NoImageFound,
            Others: NoImageFound,
            Video: NoImageFound,
    };
  }

  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };

  const getFirstAvailableType = () => {
    return Object.keys(uploadTypes).find(
      (type) => !uploadedDocsTypes.includes(type)
    );
  };





  useEffect(() => {
    setIsLoading(true);
    getUploadedMediaById(leadId)
      .then((res) => {
        const fetchedUploadedTypes = res.data.data?.uploaded_doc_types ?? [];
        setUploadedDocsTypes(fetchedUploadedTypes);
        const firstAvailableType = getFirstAvailableType();
        setSelectedType(firstAvailableType);
        setUploadedDocsDetails(res.data.data?.doc_details ?? []);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [leadId]);

  useEffect(() => {
    const allUploadedExceptOthers = Object.keys(uploadTypes).every(
      (type) => type === "Others" || uploadedDocsTypes.includes(type)
    );
    setIsSubmitEnabled(allUploadedExceptOthers);
  }, [uploadedDocsTypes]);

  const handleCaptureClose = (uploadedType) => {
    setIsCapturePage(false);
    setUploadedDocsTypes((prevTypes) => [...prevTypes, uploadedType]);
    
    // Automatically select the next available non-disabled type
    const nextAvailableType = Object.keys(uploadTypes).find(
      (type) => !uploadedDocsTypes.includes(type) && type !== uploadedType
    );
    
    setSelectedType(nextAvailableType || "");
  };
  
  const navigate = useNavigate();
  const handleSubmitInspection = () => {
    if (!isSubmitEnabled) {
      toast.error("Submit the required values");
      return;
    }

    submitInspection(leadId)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setIsSubmitEnabled(false); // Disable the button after successful submission
          navigate('/leadsList'); // Redirect using navigate
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  if (isCapturePage) {
    return (
      <Capture
        regNo={regNo}
        captureType={selectedType === "Video" ? null : "photo"}
        leadId={leadId}
        docType={selectedType}
        onClose={() => handleCaptureClose(selectedType)}
      />
    );
  }

  const isSelectedTypeDisabled = uploadedDocsTypes.includes(selectedType);

  return (
    <div className="upload-page">
      <h5>Lead Id : {leadId}</h5>
      <h5>Reg No : {regNo}</h5>
      <h5>Vehicle Type: {vehicleType}</h5>
      <div className="uploaded-toggle-container">
        <div
          className={`${
            toggleType === "UPLOAD" ? "toggle-item-active" : "toggle-item"
          }`}
          onClick={() => {
            setToggleType("UPLOAD");
          }}
        >
          Upload Photos
        </div>
        <div
          className={`${
            toggleType === "UPLOADED" ? "toggle-item-active" : "toggle-item"
          }`}
          onClick={() => {
            setToggleType("UPLOADED");
          }}
        >
          Uploaded Photos
        </div>
      </div>
      {toggleType === "UPLOAD" && (
        <>
          <div className="dropdown-container">
            <label htmlFor="uploadType">Select Upload Type:</label>
            <select
              id="uploadType"
              onChange={handleChange}
              value={selectedType}
            >
              {Object.keys(uploadTypes)?.map((type) => (
                <option
                  key={type}
                  value={type}
                  disabled={
                    uploadedDocsTypes.includes(type) && type !== "Others"
                  }
                >
                  {type}
                </option>
              ))}
            </select>
          </div>
          {selectedType && !uploadedDocsTypes.includes(selectedType) && (
            <div className="image-container">
              <img
                src={uploadTypes[selectedType]}
                alt={selectedType}
                className="sample-image"
              />
              <h2
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                  justifyContent: "center",
                }}
              >
                {selectedType}
                {uploadedDocsTypes.includes(selectedType) && (
                  <img height={20} width={20} src={GreenTick} alt="GreenTick" />
                )}
              </h2>
            </div>
          )}
          {selectedType && (
            <button
              className="custom-button"
              onClick={() => {
                setIsCapturePage(true);
              }}
              disabled={isSelectedTypeDisabled}
            >
              {selectedType === "Video" ? "Upload Video" : "Capture Photo"}
            </button>
          )}
        </>
      )}

      {toggleType === "UPLOADED" && (
        <>
          {uploadedDocsDetails?.map((docItem, i) => (
            <div key={docItem.doc_id} className="image-container">
              <h2>
                {i + 1}
                {":"} {docItem.doc_type}{" "}
                {uploadedDocsTypes.includes(docItem.doc_type) && (
                  <img height={20} width={20} src={GreenTick} alt="GreenTick" />
                )}
              </h2>
            </div>
          ))}
        </>
      )}
      <br></br><br></br>
      <button
  className={`custom-button ${!isSubmitEnabled ? "btnDisabled" : ""}`}
  onClick={handleSubmitInspection}
  disabled={!isSubmitEnabled}
>
  Submit Inspection
</button>


     
    </div>
  );
};

export default MediaListPage;
